import { ProcessedCourse } from "@/lib/course/types";
import { getI18nDateString } from "@/lib/date";
import {
  DEFAULT_USER_CURRENCY,
  PAYMENT_FREE,
  PAYMENT_PAID,
  PAYMENT_UPGRADABLE,
} from "@/lib/course/utils/constants";
import getCoursePrice from "./getCoursePrice";
import { formatPrice } from "@/utils/functions/formatting";
import isCourseArchived from "./isCourseArchived";

export type CourseInformation = {
  header: string;
  description: string;
  icon: string;
};

const levelMapped = {
  Introductory: {
    title: "Introductory",
    description: "No prior experience required",
  },
  Intermediate: {
    title: "Intermediate",
    description: "Some prior experience required",
  },
  Advanced: {
    title: "Advanced",
    description: "Extensive prior experience required",
  },
};

export function getCourseInformation(
  course: ProcessedCourse,
  paymentOption: string | null,
  hasDateColumn?: boolean,
  isArchivedExternally?: boolean,
  isContentRefresh?: boolean,
): CourseInformation[] {
  const {
    minEffort,
    maxEffort,
    pacingType,
    start,
    end,
    weeksToComplete,
    isEnrollable,
  } = course.activeCourseRun || {};

  const { startDate, registrationDeadline } =
    course.additionalMetadata[0] || {};

  const startingDate = startDate || start;
  const endDate = registrationDeadline || end;
  const endDateText = registrationDeadline ? "Enroll by" : "Ends";

  const calendarInformation = isArchivedExternally
    ? {
        header: "Not currently available",
        description: "This course is archived",
        icon: "event-busy",
      }
    : startingDate && endDate && hasDateColumn
      ? {
          header: `Starts ${getI18nDateString(new Date(startingDate), "en-US", "UTC")}`,
          description: `${endDateText} ${getI18nDateString(new Date(endDate), "en-US", "UTC")}`,
          icon: "calendar-today",
        }
      : null;

  const courseInformation = [];
  const effortInformation = weeksToComplete
    ? {
        header: `${weeksToComplete} weeks`,
        description: `${minEffort} – ${maxEffort} hours per week`,
        icon: "timelapse",
      }
    : null;

  const pacingInformation = {
    header: pacingType === "self_paced" ? "Self-paced" : "Instructor-paced",
    description:
      pacingType === "self_paced"
        ? "Progress at your own speed"
        : "Instructor-led on a course schedule",
    icon: isContentRefresh ? "person" : "people",
  };

  const coursePrice =
    course.activeCourseRun && getCoursePrice(course.activeCourseRun);
  const isArchived = course.activeCourseRun
    ? isCourseArchived(course.activeCourseRun)
    : true;
  const paymentInformation =
    coursePrice && isEnrollable && !isArchived
      ? {
          header: paymentOption === PAYMENT_PAID ? "Cost to Enroll" : "Free",
          description:
            paymentOption === PAYMENT_UPGRADABLE
              ? "Optional upgrade available"
              : paymentOption === PAYMENT_PAID
                ? formatPrice(coursePrice, DEFAULT_USER_CURRENCY)
                : paymentOption === PAYMENT_FREE
                  ? "Access to course at no cost"
                  : "",
          icon: "money-outline",
        }
      : null;

  const level = levelMapped[course.levelType as keyof typeof levelMapped];

  const levelInformation =
    pacingType === "instructor_paced"
      ? null
      : {
          header: level.title,
          description: level.description,
          icon: "speedometer",
        };

  courseInformation.push(
    ...(isContentRefresh
      ? [levelInformation, pacingInformation, effortInformation]
      : [
          effortInformation,
          calendarInformation,
          pacingInformation,
          paymentInformation,
        ]
    ).filter(Boolean),
  );

  return courseInformation.filter(
    (course): course is CourseInformation => course !== null,
  );
}
