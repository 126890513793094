import { ProcessedCourse } from "@/lib/course/types";
import getTotalWeeks from "./getTotalWeeks";

const formatTotalTimeMessage = (
  total: number,
  unit: "month" | "year",
): string => {
  return total === 1 ? `1 ${unit}` : `${total} ${unit}s`;
};

const getProgramDuration = (courses: ProcessedCourse[]) => {
  const totalWeeks = getTotalWeeks(courses);
  const totalMonths = Math.round(totalWeeks / 4);

  if (totalMonths < 12) {
    return formatTotalTimeMessage(totalMonths, "month");
  }

  const totalYears = Math.floor(totalMonths / 12);
  const totalRemainderMonths = Math.round(totalMonths % 12);

  if (totalRemainderMonths === 0) {
    return formatTotalTimeMessage(totalYears, "year");
  }
  if (totalYears === 1 && totalRemainderMonths === 1) {
    return "1 year 1 month";
  }
  if (totalYears === 1) {
    return `1 year ${totalRemainderMonths} months`;
  }
  if (totalRemainderMonths === 1) {
    return `${totalYears} years 1 month`;
  }

  return `${totalYears} years ${totalRemainderMonths} months`;
};

export default getProgramDuration;
