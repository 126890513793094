import { CourseRun } from "../../courseRun/types";
import { COURSE_RUN_AVAILABILITY_ARCHIVED } from "./constants";

// We say that a course is archived if its active course run is archived
const isCourseArchived = (courseRun: CourseRun) => {
  if (courseRun.availability) {
    return (
      courseRun.availability.toLowerCase() === COURSE_RUN_AVAILABILITY_ARCHIVED
    );
  } else {
    return false;
  }
};

export default isCourseArchived;
