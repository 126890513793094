import React from "react";
import parse from "html-react-parser";
import { SplitBackgroundProps } from "../types";
import Image from "next/image";

export default function SplitBackground({
  headingLogo,
  heading,
  body,
  certificateForUCT = undefined,
  customButton,
  showHeadingMobile = true,
}: Readonly<SplitBackgroundProps>) {
  return (
    <div className="bg-white w-screen relative left-1/2 -translate-x-1/2">
      <div className="container max-w-[1228px] mx-auto bg-white md:flex md:px-[15px] md:pr-0">
        <div className="certificate-header-column md:mr-8 relative flex flex-col justify-center md:bg-[#f2f0ef] md:skew-x-[-12deg] md:flex-[0_0_380px] md:pr-[5.25rem] md:before:content-[''] md:before:block md:before:absolute md:before:top-0 md:before:left-0 md:before:h-full md:before:w-[500%] md:before:bg-[#f2f0ef] md:before:transform md:before:-translate-x-full">
          <h2
            className={`text-primary-500 mb-0 mt-0 font-bold text-base md:text-3xl md:leading-tight md:skew-x-[12deg] ${showHeadingMobile ? "block" : "hidden md:block"}`}
          >
            {heading}
          </h2>
        </div>
        <div className="flex-1 md:my-8 bg-white px-0 md:px-6 md:px-8">
          {headingLogo && (
            <div>
              <div className="hidden md:block">
                <Image
                  src={headingLogo}
                  alt="Certificate Logo"
                  className="mb-4 object-contain"
                  width={140}
                  layout="intrinsic"
                />
              </div>
              <div className="block md:hidden">
                <Image
                  src={headingLogo}
                  alt="Certificate Logo"
                  className="mb-2 object-contain"
                  width={100}
                  layout="intrinsic"
                />
              </div>
            </div>
          )}
          <div className="prose mb-4">{parse(body ?? "")}</div>
          {certificateForUCT != null && (
            <span className="text-sm italic text-primary-500">
              {certificateForUCT
                ? "Your certificate will be issued in your legal name and sent to you digitally upon successful completion of the program, as per the stipulated requirements. Please note, due to additional administrative requirements, at times UCT course certificate delivery may be delayed by several weeks/months."
                : "Your certificate will be issued in your legal name and sent to you digitally upon successful completion of the program, as per the stipulated requirements."}
            </span>
          )}
          {customButton && <div>{customButton}</div>}
        </div>
      </div>
    </div>
  );
}
