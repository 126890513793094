import React from "react";
import { v4 as uuidv4 } from "uuid";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import styles from "./index.module.css";
import pathwayStyles from "../variants/Pathway/index.module.css";

type Props = {
  children?: React.ReactNode;
  trigger: string | React.ReactNode;
  value?: string;
  variant?: "default" | "pathway" | "pathway-dark";
};

export default function AccordionTextItem({
  children,
  trigger,
  value,
  variant,
}: Props) {
  let variantStyles;

  switch (variant) {
    case "pathway-dark":
    case "pathway":
      variantStyles = pathwayStyles;
      break;
    default:
      variantStyles = styles;
      break;
  }

  const getValue = () => {
    if (value) {
      return value;
    }
    if (typeof trigger === "string") {
      return trigger;
    }
    return uuidv4();
  };

  return (
    <AccordionItem value={getValue()} className={`${variantStyles.item ?? ""}`}>
      <AccordionTrigger className={`${variantStyles.trigger ?? ""}`}>
        <div className="py-2">
          {typeof trigger === "string" ? <span>{trigger}</span> : trigger}
        </div>
      </AccordionTrigger>
      <AccordionContent className={`${variantStyles.content ?? ""}`} asChild>
        {children}
      </AccordionContent>
    </AccordionItem>
  );
}
