import * as React from "react";
import Image from "next/image";
import { ImageLeftProps, VideoProps } from "../types";
import VideoPlayer from "@/components/VideoPlayer";

export default function ImageLeft({
  children,
  imageurl,
  imagetitle,
  videourl,
}: ImageLeftProps & VideoProps) {
  return (
    <div className="bg-white flex flex-col md:flex-row items-center">
      <div className="flex w-full md:w-1/2 justify-center md:justify-end">
        <div className="relative w-full h-auto px-4">
          {imageurl ? (
            <>
              {videourl ? (
                <VideoPlayer
                  videoUrl={videourl}
                  title={imagetitle}
                  videoThumbnail={imageurl}
                  videoContainerClassName="w-[342px] h-[280px] flex items-center"
                  videoClassName="w-[342px] h-[280px]"
                />
              ) : (
                <Image
                  src={imageurl}
                  alt={imagetitle}
                  width={600}
                  height={450}
                  layout="responsive"
                />
              )}
            </>
          ) : null}
        </div>
      </div>
      <div className="w-full md:w-1/2 text-left px-4">{children}</div>
    </div>
  );
}
