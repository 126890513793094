export function fixUrlSrc(url: string | null | undefined): string {
  if (!url) {
    return "";
  }
  // Check if the URL starts with 'http:' or 'https:'
  if (!/^https?:\/\//i.test(url) && url.startsWith("//")) {
    return `https:${url}`;
  }
  // If the URL is already correct, return it as is
  return url;
}
