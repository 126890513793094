import Cookies from "js-cookie";
import { JWT_PAYLOAD_COOKIE_NAME } from "@/lib/auth/constants";

const getJwtFromCookie = (): {
  payload: string | undefined;
} => {
  const payload = Cookies.get(JWT_PAYLOAD_COOKIE_NAME);

  return {
    payload,
  };
};

export default getJwtFromCookie;
