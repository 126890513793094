import { ProcessedCourse } from "@/lib/course/types";

const getProgramPacing = (courses: ProcessedCourse[]) => {
  const coursePacings = courses.map((course) => {
    if (course?.activeCourseRun) {
      return course.activeCourseRun.pacingType || undefined;
    }
    return undefined;
  });

  // Set removes duplicates
  const uniquePacings = new Set(coursePacings);

  if (uniquePacings.size === 1) {
    return coursePacings[0];
  }

  // mixed pacings, do not show pacing type
  return undefined;
};

export default getProgramPacing;
