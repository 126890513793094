import getJwtFromCookie from "@/lib/auth/getJwtCookieClient";
import isJwtExpired from "@/lib/auth/isJwtExpired";
import isUserLoggedIn from "@/lib/auth/isUserLoggedIn";
import refreshUserJwt from "@/lib/auth/refreshUserJwt";

export async function fetcher(url: string) {
  if (!isUserLoggedIn()) {
    return Promise.resolve(null);
  }

  const { payload } = getJwtFromCookie();
  if (!payload) {
    return Promise.resolve(null);
  }

  // Check if token is expired
  if (isJwtExpired(payload)) {
    const refreshed = await refreshUserJwt();
    if (!refreshed) {
      return Promise.resolve(null);
    }
  }

  return fetch(url).then((res) => res.json());
}
