import { ProcessedCourse } from "@/lib/course/types";

const getTotalWeeks = (courses: ProcessedCourse[]) => {
  const reducer = (totalWeeks: number, course: ProcessedCourse) => {
    let additionalWeeks = 0;
    if (course?.activeCourseRun && course.activeCourseRun.weeksToComplete) {
      additionalWeeks = course.activeCourseRun.weeksToComplete;
    }

    return totalWeeks + additionalWeeks;
  };

  return courses.reduce(reducer, 0);
};

export default getTotalWeeks;
