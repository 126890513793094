import React from "react";
import { ContentBlockData } from "./types";
import Grid from "./variants/Grid";
import TitleLeft from "./variants/TitleLeft";
import ImageLeft from "./variants/ImageLeft";
import PartnerLeft from "./variants/PartnerLeft";
import ImageRight from "./variants/ImageRight";
import ImageRightParallelogram from "./variants/ImageRightParallelogram";
import Default from "./variants/Default";
import AboutEdx from "./variants/AboutEdx";
import ImageLeftAboutUs from "./variants/ImageLeftAboutUs";
import ExpandableBlock from "./variants/ExpandableBlock";
import SplitBackground from "./variants/SplitBackground";
import VerticalImage from "./variants/VerticalImage";
import IconLeft from "./variants/IconLeft";
import Headline from "./variants/Headline";
import Quoted from "./variants/Quoted";
import MediaTextBlock from "./variants/MediaTextBlock";

export default function ContentBlock({
  variant = "title-left",
  heading,
  imageurl,
  videourl,
  title,
  imagetitle,
  width,
  height,
  children,
  body,
  certificateForUCT,
  headingLogo,
  customButton,
  id,
}: ContentBlockData) {
  switch (variant) {
    case "grid":
      return <Grid heading={heading}>{children}</Grid>;
    case "title-left":
      return <TitleLeft heading={heading}>{children}</TitleLeft>;
    case "image-left":
      return (
        <ImageLeft
          imagetitle={imagetitle}
          imageurl={imageurl}
          videourl={videourl}
        >
          {children}
        </ImageLeft>
      );
    case "partner-left":
      return (
        <PartnerLeft imagetitle={imagetitle} imageurl={imageurl}>
          {children}
        </PartnerLeft>
      );
    case "headline":
      return (
        <Headline title={title} imageurl={imageurl} imagetitle={imagetitle}>
          {children}
        </Headline>
      );
    case "media-text-block":
      return (
        <MediaTextBlock
          title={title}
          imageurl={imageurl}
          imagetitle={imagetitle}
        >
          {children}
        </MediaTextBlock>
      );
    case "icon-left":
      return (
        <IconLeft
          title={title}
          width={width}
          height={height}
          imagetitle={imagetitle}
          imageurl={imageurl}
        >
          {children}
        </IconLeft>
      );
    case "image-right":
      return (
        <ImageRight
          imageurl={imageurl}
          imagetitle={imagetitle}
          videourl={videourl}
        >
          {children}
        </ImageRight>
      );
    case "image-right-parallelogram":
      return (
        <ImageRightParallelogram imageurl={imageurl} imagetitle={imagetitle}>
          {children}
        </ImageRightParallelogram>
      );
    case "about-edx":
      return <AboutEdx />;
    case "split-background":
      return (
        <SplitBackground
          headingLogo={headingLogo}
          heading={heading}
          body={body}
          certificateForUCT={certificateForUCT}
          customButton={customButton}
        />
      );
    case "image-left-about-us":
      return (
        <ImageLeftAboutUs imageurl={imageurl} imagetitle={imagetitle}>
          {children}
        </ImageLeftAboutUs>
      );
    case "expandable-block":
      return (
        <ExpandableBlock heading={heading} blur>
          {children}
        </ExpandableBlock>
      );
    case "vertical":
      return (
        <VerticalImage
          imagetitle={imagetitle}
          imageurl={imageurl}
          videourl={videourl}
        >
          {children}
        </VerticalImage>
      );
    case "quoted":
      return (
        <Quoted imagetitle={imagetitle} imageurl={imageurl}>
          {children}
        </Quoted>
      );
    default:
      return <Default id={id}>{children}</Default>;
  }
}
