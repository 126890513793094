import * as React from "react";
import { TitleLeftProps } from "../types";

export default function TitleLeft({ heading, children }: TitleLeftProps) {
  return (
    <div className="bg-block">
      <div className="lg:flex md:flex mt-4 py-16 px-8 container w-full">
        <div className="mr-12">
          <h2 className="text-4xl">{heading}</h2>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
}
