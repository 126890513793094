import Icon from "@/components/icons";
import { ImageProps } from "../types";
import Image from "next/image";

export default function Quoted({
  imageurl = "",
  imagetitle = "",
  children,
}: ImageProps) {
  return (
    <div
      className="flex flex-col md:flex-row p-6 rounded-xl border border-1 border-putty-400 bg-white"
      style={{ lineHeight: "1.925rem" }}
    >
      {imageurl && (
        <div className="flex md:w-1/5 justify-center items-center mb-5 md:mr-5">
          <Image src={imageurl} alt={imagetitle} width={219} height={245} />
        </div>
      )}
      <div className="flex-1">
        <div className="md:flex">
          <div className="mr-6 mb-6 text-brand-500">
            <Icon type="open-quote" svgProps={{ width: 33, height: 25 }} />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
