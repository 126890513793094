import useSWR from "swr";
import { fetcher } from "./utils";
import type { Entitlement } from "./getEntitlements";

export default function useClientEntitlements() {
  const { data, isLoading, error } = useSWR<Entitlement[] | null>(
    "/api/entitlement",
    fetcher,
  );
  return {
    entitlements: data || [],
    isLoadingEntitlements: isLoading,
    entitlementsError: error,
  };
}
