import { CourseRun } from "@/lib/courseRun/types";
import { PAID_MODES } from "./constants";

const getCoursePrice = (
  courseRun: CourseRun,
  currencyToFilter: string = "",
) => {
  let price: number = 0;

  if (courseRun?.seats) {
    courseRun.seats.forEach((seat) => {
      if (
        PAID_MODES.has(seat.type) &&
        (!currencyToFilter || seat.currencyId === currencyToFilter)
      ) {
        price = seat.price;
      }
    });
  }

  return price;
};

export default getCoursePrice;
