import { ProcessedCourse } from "@/lib/course/types";

const getMinHoursOfEffort = (courses: ProcessedCourse[]) => {
  const reducer = (totalHours: number, course: ProcessedCourse) => {
    let additionalHours: number = 0;
    if (
      course?.activeCourseRun &&
      course.activeCourseRun.minEffort &&
      course.activeCourseRun.weeksToComplete
    ) {
      additionalHours =
        course.activeCourseRun.minEffort *
        course.activeCourseRun.weeksToComplete;
    }

    return totalHours + additionalHours;
  };

  return courses.reduce(reducer, 0);
};

export default getMinHoursOfEffort;
