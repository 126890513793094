import { Collaborator } from "@/lib/course/types";
import Image from "next/image";
import React from "react";

interface CollaboratorsProps {
  collaborators: Collaborator[];
  containerClassName?: string;
  titleClassName?: string;
}

export default function Collaborators({
  collaborators,
  containerClassName = "",
  titleClassName = "",
}: CollaboratorsProps) {
  return (
    <div className={containerClassName}>
      <h3 className={titleClassName}>Course created with support from</h3>
      <div className="flex flex-wrap gap-2">
        {collaborators.map((collaborator: Collaborator) => (
          <div key={collaborator.name} className="flex">
            {collaborator?.image ? (
              <div className="max-h-[100px]">
                <Image
                  src={collaborator.image}
                  alt={collaborator.name}
                  title={collaborator.name}
                  width={170}
                  height={85}
                  className="!relative"
                />
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}
