import {
  ProgramRedesignTrafficProps,
  UseProgramRedesignTraffic,
} from "./types";
import { useMonarchRule } from "../MonarchRulesProvider";

const CUSTOM_RULE_ID = "programPageRedesignTest";

const useProgramRedesignTraffic: UseProgramRedesignTraffic = () => {
  const { isLoading, value } = useMonarchRule<{
    data: ProgramRedesignTrafficProps;
    variantCustomId: string;
  }>(CUSTOM_RULE_ID, {});
  return {
    isLoading,
    showRedesign: value?.data?.showRedesign,
    programEnrollCta: value?.data?.programEnrollCta,
    headerCta: value?.data?.headerCta,
    factoidsBar: value?.data?.factoidsBar,
    sectionsOrder: value?.data?.sectionsOrder,
    showSideRail: value?.data?.showSideRail,
  };
};

export default useProgramRedesignTraffic;
