import React from "react";
import DefaultAccordion from "./variants/Default";
import Pathway from "./variants/Pathway";
import { AccordionTextProps } from "./types";

export default function AccordionText(props: AccordionTextProps) {
  const { variant } = props;
  switch (variant) {
    case "pathway":
      return <Pathway {...props} />;
    default:
      return <DefaultAccordion {...props} />;
  }
}
