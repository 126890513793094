export const staticOfacRestriction = {
  trigger: "Who can take this program?",
  description: `Unfortunately, learners residing in one or more of the following
        countries or regions will not be able to register for this program:
        Iran, Cuba and the Crimea region of Ukraine. While edX has sought
        licenses from the U.S. Office of Foreign Assets Control (OFAC) to offer
        our programs to learners in these countries and regions, the licenses we
        have received are not broad enough to allow us to offer this program in
        all locations. edX truly regrets that U.S. sanctions prevent us from
        offering all of our programs to everyone, no matter where they live.`,
};
