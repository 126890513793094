export const COURSE_PATH_PREFIX = "https://www.edx.org";
export const COURSE_STICKY_NAV_LINKS = [
  {
    label: "About",
    path: "#about",
  },
  {
    label: "What you'll learn",
    path: "#what-youll-learn",
  },
  {
    label: "Syllabus",
    path: "#syllabus",
  },
  {
    label: "Instructors",
    path: "#instructors",
  },
  {
    label: "FAQs",
    path: "#faq",
  },
  {
    label: "Ways to enroll",
    path: "#ways-to-enroll",
  },
];
export const FBE_EXEMPT_COURSES_MAP = [
  "e10d2641-f283-4cd9-be39-d61a0aa42fe3",
  "c9d0677f-ad86-49a0-94ed-87a7f5a4f660",
  "7b5c6514-5576-4871-99c4-e36eada7911a",
  "609e7b4b-a642-4f97-ab17-c5643c905666",
  "86bd9937-82eb-42c0-9acd-ad32445424d7",
  "322eed0a-1534-4a29-a337-595f58e1fac2",
  "3b490285-4eed-4f5f-b746-2e65c8ad60fb",
  "2c9ed992-c007-4182-a9f4-7524b2029082",
  "2f0dfe94-aeb4-4f7a-8dd4-4e8691cd6c0d",
  "bfd20bb1-5a8a-4545-a6e3-1a32dc02f588",
  "08f162d1-6943-490b-8e0b-ee6fd5ab256e",
  "ad7fb96d-692c-4055-911b-a4a1fff340a9",
  "95a1b16c-21b0-471e-8afa-cfde0a50c242",
  "94b90255-49fa-4997-b01b-435fe810af3d",
  "2cfebcea-dd5b-438e-88a0-600d4ed11d5b",
  "7c6f7c8f-9c5d-4943-a140-6f26d096fa78",
  "a9fb1017-cfa0-4ec5-9bbd-bd8562413a27",
  "32779451-9094-45c8-9805-505bfe77ae02",
  "1aafa52a-a1e4-4fc1-be25-fe3e468773dc",
  "d06880fb-ad82-4e95-9dfe-7a1e43487115",
  "6e418821-0e4c-4b16-94a3-18979ee49717",
  "156c045f-5815-4038-bfd5-c0e044fe8c29",
  "cfb52e08-9ef1-4670-82de-83395f0b9703",
  "502c45d3-bc49-4409-922d-10b9c89bf509",
  "e4315d22-de5d-4574-951a-be74baa8a8fa",
  "f21c583c-786d-4ace-b63e-270fe2cb3e6d",
  "ed9d9041-2ed3-4afb-9dd9-adc2527017b2",
  "5eeb2d39-3f33-494b-ab2a-d44e746851e0",
  "6de034c4-abf7-45d3-88dc-cf09058bd7d0",
  "c73549de-a137-45bb-99d0-a9954efea30f",
  "6b513081-d791-48e3-8c7a-e20f4ff61ff3",
  "f8507c60-d462-4eb9-95ee-a70b025b2b53",
  "8e6cfbbd-b68b-483a-9108-5d4658278dd5",
  "3b428d3f-f451-4deb-8da1-ac6ec1a4510c",
  "6ef185bc-c3fd-426d-befc-acd3d0c7601c",
  "36a24954-65ad-4b17-8a0b-8b3ddea4acc8",
  "6e36cf37-db6f-4643-8928-587536138bf4",
  "b3ca409d-ade4-43d1-b082-6ba89b3f9103",
  "1fbfd3aa-7525-4148-8b1f-c78381ef04cf",
  "e7db6934-a536-410b-9a10-c9d8231cf498",
  "238d5565-3069-4656-9057-eed0f62a0195",
  "efc25613-f0ea-4423-bfcd-4d94c317f085",
  "0d232bd1-92d2-4528-8313-efd8fb6e43ce",
  "fb27f6c8-5f32-4e1e-b67f-9093b8945fa5",
  "baddd037-25ed-43bd-8878-3fb9a28257c0",
  "520c39be-89c1-4cad-9e9b-ff1f0cdc8b99",
  "7c6cf108-a32b-4f25-911b-e5693988abe2",
  "c96ac2eb-e620-41a2-87e2-bc6081486540",
  "1c807086-e3fb-4a0c-8815-c3bd9e33e8a6",
  "7806a154-64df-404e-a467-81c43dc0fd6a",
  "63182838-cdc4-4d16-9a5c-e150cc725ba2",
  "662eebf9-b079-4152-a33f-5d73035eabb9",
  "7f4026ba-287a-462b-87c1-c65b79f6630c",
  "19697253-fdef-4265-824c-86267c79c40b",
  "98ab18cc-e830-4acb-adb5-16225c149bf5",
  "6400924f-9cff-4be0-8650-116576457b3f",
  "64ec3a8f-f0cb-4a79-a8d7-696349925582",
  "315ce06a-d9f7-4c7e-ac11-1fe99e20893e",
  "0c675c29-54ee-42ff-ac95-01f53450bc8b",
  "da1b2400-322b-459b-97b0-0c557f05d017",
  "98955fca-5874-45a9-b2d5-278e6694c6ed",
  "9e0d9bd0-8557-49bc-a949-4fc7ff7727ac",
  "8f8e5124-1dab-47e6-8fa6-3fbdc0738f0a",
  "3a31db71-de8f-45f1-ae65-11981ed9d680",
  "8290161d-6419-41c6-87a8-b0fa61b8bb86",
  "3e45c431-10df-423e-9f03-fb98b713cd4a",
  "88391405-e329-495d-bf72-5de13bbf1846",
  "2cc794d0-316d-42f7-bbfd-25c34e4cd5df",
  "ce432996-29c3-4c62-944d-9d9c3b9b55ff",
  "448cd3c7-d2db-4ad8-9185-f08557f648ce",
  "99fa178b-8715-4906-ae6d-5e5dfb932f4d",
  "fd350cb2-e772-4fd5-9075-f886512874a2",
  "fa2145ce-c646-424d-b8dc-cfc188d5ef33",
  "cabd0da4-430b-4cad-9c7c-87c8eb6ed456",
  "868bf395-26eb-4c52-affa-f44904876682",
];
export const PAYMENT_FREE = "free";
export const PAYMENT_UPGRADABLE = "upgradable";
export const PAYMENT_PAID = "paid";
export const PAID_MODES = new Set(["verified", "professional"]);
export const LMS_DOMAIN = "https://courses.edx.org";
export const AUTH_DOMAIN = "https://authn.edx.org";
export const LMS_COURSE_DOMAIN = "https://learning.edx.org";
export const COURSE_RUN_AVAILABILITY_ARCHIVED = "archived";
export const COURSE_PACING_SELF = "self_paced";
export const COURSE_PACING_INSTRUCTOR = "instructor_paced";
export const DEFAULT_USER_CURRENCY = { code: "USD", rate: 1.0, symbol: "$" };
export const PACING_TYPE_SELF = "self_paced";
export const PACING_TYPE_INSTRUCTOR = "instructor_paced";
