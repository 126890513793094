import * as React from "react";
import Image from "next/image";
import { ImageLeftProps } from "../types";

export default function ImageLeftAboutUs({
  children,
  imageurl,
  imagetitle,
}: ImageLeftProps) {
  return (
    <div className="bg-putty-100 mx-break-out relative lg:min-h-[500px]">
      <div className="not-prose hidden lg:block absolute left-0 top-0 h-[500px] w-1/3">
        <Image
          src={imageurl}
          alt={imagetitle}
          layout="fill"
          objectFit="cover"
          style={{ overflowX: "visible" }}
        />
      </div>
      <div className="container flex flex-col lg:flex-row lg:justify-end items-center px-6 py-12 lg:min-h-[500px]">
        <div className="lg:w-3/5 w-full text-left lg:pl-6">{children}</div>
      </div>
    </div>
  );
}
