import Image from "next/image";
import { fixUrlSrc } from "@/utils/functions/fixUrlSrc";
import CTALink from "@/components/CTA/variants/CtaLink";
import { ImageLeftProps } from "../types";

export default function MediaTextBlock({
  id,
  children,
  title,
  imageurl,
  imagetitle,
}: ImageLeftProps) {
  const image = fixUrlSrc(imageurl);

  return (
    <div className="fullwidth py-4 bg-putty-100">
      <div id={id} className="py-4 scroll-m-20">
        <div className="flex flex-col lg:flex-row-reverse">
          <div className="flex before:bg-brand-500 before:-skew-x-6 before:w-[6px] before:h-1/2 lg:before:relative before:top-1/2 justify-center min-w-[400px]">
            <Image
              className="clip-slant-x-both"
              src={image}
              alt={imagetitle}
              width={338}
              height={269}
            />
          </div>
          <div className="prose-xl prose-a:text-link prose-a:underline hover:prose-a:text-primary text-lg prose-p:text-lg">
            {title && <h2 className="text-3xl py-6 m-0">{title}</h2>}
            {children}
            <CTALink
              type="brand"
              label="Request more info"
              url="#requestmoreinfo"
              icon="arrow-right"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
