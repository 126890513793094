import React from "react";
import parse from "html-react-parser";
import ExpandableBlock from "@/components/ContentBlock/variants/ExpandableBlock";
import VideoPlayer from "@/components/VideoPlayer";
import { Props } from "./types";
import Sponsors from "../Sponsors";
import Collaborators from "../Collaborators";

export function ContentOverview({
  video,
  fullDescription,
  title = "About this course",
  courseData,
}: Props) {
  const styledFullDescription = fullDescription
    .replace(/<p>/g, '<p class="text-gray-800">')
    .replace(/<ul>/g, '<ul class="list-disc pl-4 pb-4">')
    .replace(/<li>/g, '<li class="ml-4 list-outside text-gray-800">')
    .replace(/<a /g, '<a class="text-info-500 underline" ');

  const sponsors = courseData?.sponsors?.length ? courseData.sponsors : [];
  const collaborators = courseData?.collaborators?.length
    ? courseData.collaborators
    : [];
  return (
    <div
      className={`relative grid grid-cols-1 ${
        video?.src ? "lg:grid-cols-2" : "lg:grid-cols-1"
      } gap-y-12 lg:gap-12`}
    >
      <div className="flex flex-col gap-4">
        <h2 className="text-2xl font-bold mt-0 mb-0">{title}</h2>
        <ExpandableBlock
          blur
          btnClassName="text-gray-800 underline-offset-4 mt-4"
          blurClassName="from-putty-100 via-putty-100/80"
        >
          <p className="text-gray-800">{parse(styledFullDescription)}</p>
          <div className="hidden md:block">
            {sponsors?.length > 0 && sponsors[0] != null ? (
              <Sponsors
                sponsors={sponsors}
                containerClassName="flex flex-col gap-4"
                titleClassName="m-0"
              />
            ) : null}
            {collaborators?.length > 0 && collaborators[0] != null ? (
              <Collaborators
                containerClassName="flex flex-col gap-4"
                titleClassName="m-0"
                collaborators={collaborators}
              />
            ) : null}
          </div>
        </ExpandableBlock>
      </div>

      {video?.src && (
        <div className="w-full flex justify-center items-start">
          <div className="w-full max-h-[40rem] flex justify-center">
            <VideoPlayer
              videoUrl={video.src}
              title={"Course Video"}
              videoThumbnail={video.image?.src}
              videoContainerClassName="relative w-full h-full flex items-center justify-center"
              videoClassName="w-full h-full"
              thumbnailClassName="object-cover w-full h-full max-h-none max-w-none"
              thumbnailWidth={400}
              thumbnailHeight={225}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ContentOverview;
