import { cn } from "@/lib/utils";

interface OFACRestrictionsProps {
  hasOfacRestrictions?: boolean;
  className?: string;
}

export const OFACText: string = `Unfortunately, learners residing in one or more of the following countries or regions will not be able to register for this course:
    Iran, Cuba and the Crimea region of Ukraine. While edX has sought licenses from the U.S. Office of Foreign Assets Control (OFAC) to
    offer our courses to learners in these countries and regions, the licenses we have received are not broad enough to allow us to offer
    this course in all locations. edX truly regrets that U.S. sanctions prevent us from offering all of our courses to everyone, no matter where they live.`;

function OFACRestrictions({
  hasOfacRestrictions = true,
  className,
}: OFACRestrictionsProps) {
  if (hasOfacRestrictions) {
    const classes = cn(
      "flex flex-col justify-center align-center md:mb-12",
      className,
    );

    return (
      <div className={classes}>
        <h2 className="text-primary text-base md:text-3xl font-bold hidden md:block">
          Who can take this course?
        </h2>
        <p className="text-primary text-base md:text-3xl font-bold block md:hidden mt-3">
          Who can take this course?
        </p>
        <p>{OFACText}</p>
      </div>
    );
  }
  return null;
}

export default OFACRestrictions;
