"use client";

import { getResponseError } from "@/lib/utils";

const refreshUserJwt = async () => {
  const response = await fetch("https://courses.edx.org/login_refresh", {
    method: "POST",
    credentials: "include",
  });

  if (!response.ok) {
    const error = await getResponseError(response);
    window.newrelic.noticeError(error);
    return false;
  }

  return true;
};

export default refreshUserJwt;
