import React from "react";
import Icon from "@/components/icons";
import { IconTypes } from "@/components/icons/types";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";

type ShareButtonType =
  | typeof FacebookShareButton
  | typeof LinkedinShareButton
  | typeof TwitterShareButton
  | typeof EmailShareButton;

type SocialLinkProps = {
  source: string;
  icon: string;
  sr: string;
  component: ShareButtonType;
};

type SocialShareProps = {
  title: string;
  productUrl: string;
  productName: string;
  className?: string;
};

const socialLinks: SocialLinkProps[] = [
  {
    source: "facebook",
    icon: "facebook-circle",
    sr: "Share this course on facebook",
    component: FacebookShareButton,
  },
  {
    source: "x",
    icon: "x",
    sr: "Share this course on X",
    component: TwitterShareButton,
  },
  {
    source: "linkedin",
    icon: "linkedin-circle",
    sr: "Share this course on linkedin",
    component: LinkedinShareButton,
  },
  {
    source: "email",
    icon: "mail",
    sr: "Share this course via email",
    component: EmailShareButton,
  },
];

export default function SocialShare({
  title,
  productUrl,
  productName,
  className = "",
}: SocialShareProps) {
  const getShareLinkWithUtmParams = (source: string) => {
    const shareUrl = productUrl;
    if (!source || source === "") {
      return shareUrl;
    }
    const campaign = "social-sharing-course-page";
    const medium = "social";
    return `${shareUrl}?utm_campaign=${campaign}&utm_medium=${medium}&utm_source=${source}`;
  };

  return (
    <div className={`md:bg-putty-100 w-full mx-auto ${className}`}>
      <h2 className="text-xl text-center mt-0 mb-4">{title}</h2>
      <div className="mb-4 justify-center flex flex-row">
        {socialLinks.map((socialLink, index) => {
          const ShareComponent = socialLink.component;
          return (
            <div key={index} className="mx-2 flex flex-col">
              <ShareComponent
                url={getShareLinkWithUtmParams(socialLink.source)}
                {...(socialLink.source === "email" && { subject: productName })}
                {...(socialLink.source === "x" && { title: productName })}
              >
                <div className="flex bg-primary text-white border border-solid border-primary h-12 w-12 items-center justify-center rounded-full">
                  <Icon type={socialLink.icon as IconTypes} />
                  <span className="sr-only">{socialLink.sr}</span>
                </div>
              </ShareComponent>
            </div>
          );
        })}
      </div>
    </div>
  );
}
