import * as React from "react";
import { ImageProps, VideoProps } from "../types";
import Image from "next/image";
import CTALink from "@/components/CTA/ctaLink";
import VideoPlayer from "@/components/VideoPlayer";

export default function ImageRight({
  children,
  imageurl,
  imagetitle,
  videourl,
}: ImageProps & VideoProps) {
  const hasCTALink = React.Children.toArray(children).some((child) => {
    if (React.isValidElement(child)) {
      const element = child as React.ReactElement<{
        children?: React.ReactNode;
      }>;
      if (React.isValidElement(element.props.children)) {
        const childElement = element.props.children;
        return childElement.type === CTALink;
      }
    }
    return false;
  });

  if (!hasCTALink) {
    return (
      <div className="flex flex-col-reverse md:flex-row py-8 container items-center">
        <div className="min-w-[300px] md:mr-12">{children}</div>
        <div className="flex justify-center">
          {imageurl ? (
            <div className="w-[342px] h-[280px] flex items-center">
              {videourl ? (
                <VideoPlayer
                  videoUrl={videourl}
                  title={imagetitle}
                  videoThumbnail={imageurl}
                  videoContainerClassName="w-[342px] h-[280px] flex items-center"
                  videoClassName="w-[342px] h-[280px]"
                />
              ) : (
                <Image
                  layout="responsive"
                  width={342}
                  height={280}
                  src={imageurl}
                  alt={imagetitle}
                  className="block object-cover"
                />
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  } else {
    return (
      <div className="bg-white flex flex-col md:flex-row container items-center">
        <div className="relative w-full h-auto px-4">{children}</div>
        {imageurl ? (
          <div className="w-[342px] h-[280px] flex items-center">
            {videourl ? (
              <VideoPlayer
                videoUrl={videourl}
                title={imagetitle}
                videoThumbnail={imageurl}
                videoContainerClassName="w-[342px] h-[280px] flex items-center"
                videoClassName="w-[342px] h-[280px]"
              />
            ) : (
              <Image
                layout="responsive"
                width={342}
                height={280}
                src={imageurl}
                alt={imagetitle}
                className="block object-cover"
              />
            )}
          </div>
        ) : null}
      </div>
    );
  }
}
