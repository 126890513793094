import React from "react";
import DefaultInstructorList from "./variants/DefaultInstructorList";
import { InstructorListProps } from "./types";
import ExpandableInstructorsList from "./variants/Expandable";
import TextRightInstructorsList from "./variants/TextRight";

export default function InstructorsList(props: InstructorListProps) {
  //centralized organizationOverride handling for all instructors list variants
  const instructors = props.instructors.map((instructor) => {
    return {
      ...instructor,
      organizationName:
        instructor.organizationOverride ||
        instructor.partnerName ||
        instructor.partners?.[0]?.name ||
        "",
    };
  });

  switch (props.variant) {
    case "expandable":
      return <ExpandableInstructorsList {...props} instructors={instructors} />;
    case "text-right":
      return <TextRightInstructorsList {...props} instructors={instructors} />;
    default:
      return <DefaultInstructorList {...props} instructors={instructors} />;
  }
}
