import Icon from "@/components/icons";
import { IconTypes } from "@/components/icons/types";

type IconDescriptionProps = {
  iconType: IconTypes;
  title: string | React.ReactNode;
  subtitle: string;
  svgProps?: React.SVGProps<SVGSVGElement>;
  textColor?: string;
};

export default function IconDescription({
  iconType,
  title,
  subtitle,
  svgProps = { width: "40", height: "40" },
  textColor = "text-putty-200",
}: IconDescriptionProps) {
  return (
    <>
      <div className={`${textColor} flex flex-row`}>
        <Icon type={iconType} svgProps={svgProps} />
        <div className="ml-5">
          <span className="mt-0 font-bold text-lg">{title}</span>
          <br />
          <span className="text-sm">{subtitle}</span>
        </div>
      </div>
    </>
  );
}
