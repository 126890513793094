import * as React from "react";
import { GridProps } from "../types";
export default function Grid({ heading, children }: GridProps) {
  return (
    <div className="container mx-auto p-[1.875rem] w-full border-b-[10px] border-light">
      <h3 className="font-light uppercase text-primary border-b border-[#8a8c8f40] mb-4 mt-0 pb-2.5 text-[1.5rem] leading-[2.2rem] tracking-[0.1rem]">
        {heading}
      </h3>
      <div className="flex flex-col md:flex-row md:-mx-2">{children}</div>
    </div>
  );
}
