import React from "react";
import ExpandableInstructorsList from "./Expandable";
import { PartialPartner } from "@/lib/partner/types";
import { InstructorListInstanceProps } from "../types";

const TextRightInstructorsList: React.FC<InstructorListInstanceProps> = ({
  instructors,
  partners,
}) => {
  const getPartnerOrgs = (partners: PartialPartner[] | null) => {
    let partnerOrgs: string[] = [];
    if (partners) {
      partners.forEach((partner) => {
        const orgAndKey =
          partner?.key === partner?.name
            ? `${partner?.name}`
            : `${partner?.name} (${partner?.key})`;
        partnerOrgs.push(orgAndKey);
      });
    }
    return partnerOrgs.join(", ");
  };

  if (!instructors || instructors.length === 0) {
    return <></>;
  }

  const authoringOrg = partners ? getPartnerOrgs(partners) : null;
  const { partnerName, partnerKey } = instructors[0];

  return (
    <div className="container mx-auto p-4">
      <div className={"overflow-hidden opacity-100 flex flex-col"}>
        <h2 className="flex flex-col font-bold text-2xl self-center text-center items-center mb-3">
          <div>Meet your instructor{instructors.length > 1 ? "s" : ""}</div>
          {authoringOrg ? (
            <div>from {authoringOrg}</div>
          ) : (
            <div>
              from {partnerName} ({partnerKey})
            </div>
          )}
        </h2>
        <div className="flex flex-col md:flex-row  w-full">
          <ExpandableInstructorsList
            instructors={instructors}
            preExpandedThreshold={4}
          />
          <div className="w-full md:w-1/3 text-xl">
            <span className="inline box-decoration-clone bg-accent-yellow leading-10 pl-6 pr-4">
              Experts{authoringOrg && ` from ${authoringOrg}`} committed to
              teaching online learning
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextRightInstructorsList;
