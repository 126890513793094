import * as React from "react";
import Image from "next/image";
import { ImageLeftProps } from "../types";

export default function IconLeft({
  children,
  title,
  imageurl,
  imagetitle,
  width = 64,
  height = 64,
}: ImageLeftProps) {
  return (
    <div className="bg-white">
      <div className="flex items-center mb-4.5">
        <Image
          className="w-16 h-16"
          src={imageurl!}
          alt={imagetitle!}
          width={width}
          height={height}
        />
        <h3 className="m-0 text-2xl font-bold leading-9">{title}</h3>
      </div>
      <div className="w-full text-left px-4">{children}</div>
    </div>
  );
}
