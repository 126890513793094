"use client";
import { useState, useEffect, useRef, useCallback, ReactNode } from "react";

type Props = {
  children: ReactNode;
  forceShow?: boolean;
};
export default function StickyCTAWrapper({
  children,
  forceShow = false,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [isNavOnTop, setIsNavOnTop] = useState(false);

  const onScroll = useCallback(() => {
    if (!ref.current) return;

    const rect = ref.current.getBoundingClientRect();
    const newIsNavOnTop = rect.top <= 25;

    setIsNavOnTop((prev) => (prev !== newIsNavOnTop ? newIsNavOnTop : prev));
  }, []);

  useEffect(() => {
    if (forceShow) {
      setIsNavOnTop(true);
      return;
    }
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, [onScroll, forceShow]);

  return (
    <div
      className="block sticky top-0 w-screen ml-[calc(-50vw+50%)] bg-primary z-50"
      ref={ref}
    >
      {isNavOnTop && children}
    </div>
  );
}
