import useSWR from "swr";
import { fetcher } from "./utils";
import type { Enrollment } from "./getEnrollments";

export default function useClientEnrollments() {
  const { data, isLoading, error } = useSWR<Enrollment[] | null>(
    "/api/enrollment",
    fetcher,
  );
  return {
    enrollments: data || [],
    isLoadingEnrollments: isLoading,
    enrollmentsError: error,
  };
}
