import { ProcessedCourse } from "@/lib/course/types";
import getProgramPacing from "@/lib/program/utils/getProgramPacing";
import getProgramDuration from "@/lib/program/utils/getProgramDuration";
import getTotalEstimatedEffortInHoursPerWeek from "@/lib/program/utils/getTotalEstimatedEffortInHoursPerWeek";

export type CourseInformation = {
  header: string;
  description: string;
  icon: string;
};

const levelMapped = {
  Introductory: {
    title: "Introductory",
    description: "No prior experience required",
  },
  Intermediate: {
    title: "Intermediate",
    description: "Some prior experience required",
  },
  Advanced: {
    title: "Advanced",
    description: "Extensive prior experience required",
  },
};

export function getProgramInformation(
  courses: ProcessedCourse[],
): CourseInformation[] {
  const pacingType = getProgramPacing(courses);
  const duration = courses && getProgramDuration(courses);

  const courseInformation = [];
  const effortInformation = duration
    ? {
        header: `${duration}`,
        description: `${getTotalEstimatedEffortInHoursPerWeek(courses)}`,
        icon: "timelapse",
      }
    : null;

  const pacingInformation = {
    header: pacingType === "self_paced" ? "Self-paced" : "Instructor-paced",
    description:
      pacingType === "self_paced"
        ? "Progress at your own speed"
        : "Instructor-led on a course schedule",
    icon: "person",
  };

  const level = levelMapped[courses[0].levelType as keyof typeof levelMapped];

  const levelInformation =
    pacingType === "instructor_paced"
      ? null
      : {
          header: level.title,
          description: level.description,
          icon: "speedometer",
        };

  const coursesInformation = {
    header: `${courses.length} courses`,
    description: "Learn more",
    icon: "certificate",
    overviewLink: "#courses",
  };

  if (levelInformation) courseInformation.push(levelInformation);
  courseInformation.push(pacingInformation);
  if (effortInformation) courseInformation.push(effortInformation);
  courseInformation.push(coursesInformation);

  return courseInformation;
}
