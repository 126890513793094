import React from "react";
import { Accordion } from "@/components/ui/accordion";

type Props = {
  children?: React.ReactNode;
};

export default function AccordionText({ children }: Props) {
  // Only get valid Elements (without ,)
  const childrenArray = React.Children.toArray(children).filter((child) =>
    React.isValidElement(child),
  );

  return (
    <Accordion
      type="multiple"
      className="flex flex-col gap-6 [&_button]:no-underline [&_button]:text-gray-dark [&_button:hover]:text-gray-dark"
    >
      {childrenArray}
    </Accordion>
  );
}
