import * as React from "react";
import Image from "next/image";

// only static data is used in this component
export default function AboutEdx() {
  const headerClassName = "font-bold text-primary mb-1 text-lg";
  return (
    <div className="py-16 w-full">
      <div className="flex flex-row text-balance py-4 px-8 container w-full">
        <div className="md:basis-1/2">
          <p>
            Whether you are looking to accelerate your career, earn a degree, or
            learn something for personal reasons,{" "}
            <span className="font-bold">edX has the courses for you</span>.
          </p>
          <div>
            <div className="flex flex-col md:flex-row">
              <div className="md:basis-1/2">
                <p className={headerClassName}>Propelling</p>
                <p>
                  Drive your career forward with university-backed credit
                  programs and verified certificates
                </p>
              </div>
              <div className="md:basis-1/2">
                <p className={headerClassName}>Convenient</p>
                <p>Study and demonstrate knowledge on your schedule</p>
              </div>
            </div>
            <div className="flex flex-col md:flex-row">
              <div className="md:basis-1/2">
                <p className={headerClassName}>Flexible</p>
                <p>Try a course before you pay</p>
              </div>
              <div className="md:basis-1/2">
                <p className={headerClassName}>Supportive</p>
                <p>
                  Learn with university partners and peers from around the world
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="md:basis-1/2 flex justify-center align-center hidden md:block">
          <Image
            className="mr-2"
            width={400}
            height={312}
            src={"/images/world_map.webp"}
            alt="About edX"
          />
        </div>
      </div>
    </div>
  );
}
