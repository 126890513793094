export const getI18nDateString = (
  date: Date,
  locale = "en-US",
  timeZone?: string,
) => {
  return new Date(date).toLocaleDateString(locale, {
    day: "numeric",
    month: "short",
    year: "numeric",
    timeZone: timeZone || "UTC",
  });
};

export const isDateInPast = (end: Date) => new Date(end) < new Date();

export const getI18nDayMonthString = (date: Date, locale = "en-US") => {
  return new Date(date).toLocaleDateString(locale, {
    day: "numeric",
    month: "short",
    timeZone: "UTC",
  });
};

export const getDaysAfterDate = (date: Date, int = 1) => {
  const dateObj = new Date(date);
  const daysAfter = new Date(dateObj.toISOString());
  daysAfter.setUTCDate(dateObj.getUTCDate() + int);
  return daysAfter;
};

export const getMonthsAfterDate = (date: Date, int = 1) => {
  const dateObj = new Date(date);
  const monthsAfter = new Date(dateObj.toISOString());
  monthsAfter.setUTCMonth(dateObj.getUTCMonth() + int);
  return monthsAfter;
};

export const getYearsAfterDate = (date: Date, int = 1) => {
  const dateObj = new Date(date);
  const yearsAfter = new Date(dateObj.toISOString());
  yearsAfter.setUTCFullYear(dateObj.getUTCFullYear() + int);
  return yearsAfter;
};

export const getDateDayMonth = (date: Date) => {
  const currentYear = new Date().getUTCFullYear();
  const stringDate = `${date.toLocaleString("en-US", { month: "short" })} ${date.getDate()}`;

  if (date.getUTCFullYear() > currentYear) {
    return `${stringDate}`;
  }
  return stringDate;
};

// returns a boolean if given date ends within this year
export const isEndingWithinThisYear = (date: Date) =>
  new Date(date).getUTCFullYear() <
  getYearsAfterDate(new Date(), 1).getUTCFullYear();

export function isDateInCurrentYear(date: Date): boolean {
  const currentYear = new Date().getUTCFullYear();
  const inputYear = date.getUTCFullYear();
  return inputYear === currentYear;
}

export const getI18nDateContextualString = (
  date: Date,
  locale = "en-US",
  timeZone?: string,
) => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    timeZone: timeZone || "UTC",
  };
  const inCurrentYear = isDateInCurrentYear(date);
  return new Date(date).toLocaleDateString(locale, {
    ...dateOptions,
    ...(!inCurrentYear ? { year: "numeric" } : {}),
  });
};
