import { ProcessedCourse } from "@/lib/course/types";

const getMaxHoursOfEffort = (courses: ProcessedCourse[]) => {
  const reducer = (totalHours: number, course: ProcessedCourse) => {
    let additionalHours = 0;
    if (
      course?.activeCourseRun &&
      course.activeCourseRun.maxEffort &&
      course.activeCourseRun.weeksToComplete
    ) {
      additionalHours =
        course.activeCourseRun.maxEffort *
        course.activeCourseRun.weeksToComplete;
    }

    return totalHours + additionalHours;
  };

  return courses.reduce(reducer, 0);
};

export default getMaxHoursOfEffort;
