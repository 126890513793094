import * as React from "react";
import Image from "next/image";
import { PartnerLeftProps, VideoProps } from "../types";
import VideoPlayer from "@/components/VideoPlayer";

export default function PartnerLeft({
  children,
  imageurl,
  imagetitle,
  videourl,
}: PartnerLeftProps & VideoProps) {
  return (
    <div className="bg-white flex flex-col lg:flex-row items-center md:mx-48">
      <div className="flex w-full lg:w-1/4 justify-center md:justify-end">
        <div className="w-full h-auto px-4">
          {imageurl ? (
            <>
              {videourl ? (
                <VideoPlayer
                  videoUrl={videourl}
                  title={imagetitle}
                  videoThumbnail={imageurl}
                  videoContainerClassName="w-[342px] h-[280px] flex items-center"
                  videoClassName="w-[342px] h-[280px]"
                />
              ) : (
                <Image
                  src={imageurl}
                  alt={imagetitle}
                  className="my-0"
                  width={300}
                  height={150}
                />
              )}
            </>
          ) : null}
        </div>
      </div>
      <div className="w-full lg:w-3/4 text-left px-4">{children}</div>
    </div>
  );
}
