import { ProcessedCourse } from "@/lib/course/types";
import getMinHoursOfEffort from "./getMinHoursOfEffort";
import getMaxHoursOfEffort from "./getMaxHoursOfEffort";
import getTotalWeeks from "./getTotalWeeks";

const getTotalEstimatedEffortInHoursPerWeek = (courses: ProcessedCourse[]) => {
  const minTotalHours = getMinHoursOfEffort(courses);
  const maxTotalHours = getMaxHoursOfEffort(courses);
  const totalWeeks = getTotalWeeks(courses);

  const minAverageHours = Math.round(minTotalHours / totalWeeks);

  if (minTotalHours === maxTotalHours) {
    return String(minAverageHours);
  }

  const maxAverageHours = Math.round(maxTotalHours / totalWeeks);
  return `${minAverageHours} - ${maxAverageHours} hours per week`;
};

export default getTotalEstimatedEffortInHoursPerWeek;
