import { Instructor } from "../../instructor/types";

const getInstructorFullName = (
  instructor: Omit<Instructor, "aboutMe">,
): string => {
  const { firstName, lastName } = instructor;
  return `${firstName} ${lastName}`.trim();
};

export default getInstructorFullName;
