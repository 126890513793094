"use client";
import React from "react";
import { PartialPartner } from "@/lib/partner/types";

type Props = {
  partners: PartialPartner[];
  optIn: boolean;
  title: string;
  toggleEmailOptIn: () => void;
  variant?: "default" | "secondary";
  checkboxId?: string;
};

export default function EmailOptIn({
  partners,
  optIn,
  title,
  toggleEmailOptIn,
  variant = "default",
  checkboxId = "email-opt-in",
}: Readonly<Props>) {
  const getPartnerList = () => {
    const list = partners.map(({ key }) => key);

    return list.join(", ");
  };

  const containerClasses =
    variant === "default" ? "mb-3 pb-1 mb-sm-4 pb-sm-0" : "";
  const labelClasses =
    variant === "default"
      ? "text-xs text-gray-500 leading-5"
      : "text-sm text-gray-700";

  return (
    <div className={`form-field d-flex align-items-start ${containerClasses}`}>
      <div className="flex items-start space-x-2">
        <input
          type="checkbox"
          id={checkboxId}
          className="h-5 w-5 border-gray-400 text-primary focus:ring-2 focus:ring-primary focus:ring-offset-2"
          checked={optIn}
          onChange={toggleEmailOptIn}
        />
        <label htmlFor={checkboxId} className={labelClasses}>
          I would like to receive email from {getPartnerList()} and learn about
          other offerings related to {title}.
        </label>
      </div>
    </div>
  );
}
